/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function PCHeader(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="1440px"
      height="150px"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(104,134,73,1)"
      {...rest}
      {...getOverrideProps(overrides, "PCHeader")}
    >
      <Image
        position="absolute"
        top="47px"
        bottom="47px"
        height="calc(100% - 94px)"
        left="48px"
        right="1170.58px"
        width="calc(100% - 1218.58px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
      <Flex
        gap="0"
        position="absolute"
        top="78px"
        left="988px"
        direction="column"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Tab")}
      >
        <Flex
          gap="0"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 29px 0px 0px"
          {...getOverrideProps(overrides, "HeaderButton")}
        >
          <Flex
            gap="4px"
            direction="row"
            width="211px"
            height="67px"
            justifyContent="center"
            alignItems="flex-end"
            shrink="0"
            position="relative"
            borderRadius="22px 22px 0px 0px"
            padding="12px 16px 15px 16px"
            backgroundColor="rgba(221,121,47,1)"
            {...getOverrideProps(overrides, "Colum")}
          >
            <Flex
              gap="7px"
              direction="column"
              shrink="0"
              height="33px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ColumBody")}
            >
              <Flex
                gap="4px"
                direction="row"
                width="fit-content"
                justifyContent="center"
                alignItems="flex-end"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "ColumText")}
              >
                <Text
                  fontFamily="Shippori Mincho"
                  fontSize="26px"
                  fontWeight="600"
                  color="rgba(232,232,232,1)"
                  lineHeight="26px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-end"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="コラム"
                  {...getOverrideProps(overrides, "\u30B3\u30E9\u30E0")}
                ></Text>
                <Text
                  fontFamily="Shippori Mincho"
                  fontSize="18px"
                  fontWeight="600"
                  color="rgba(232,232,232,1)"
                  lineHeight="20px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="を読む"
                  {...getOverrideProps(overrides, "\u3092\u8AAD\u3080")}
                ></Text>
              </Flex>
              <Icon
                width="136px"
                height="0px"
                viewBox={{ minX: 0, minY: 0, width: 136, height: 1 }}
                paths={[
                  {
                    d: "M0 0L136 0L136 -1L0 -1L0 0Z",
                    stroke: "rgba(232,232,232,1)",
                    fillRule: "nonzero",
                    strokeWidth: 1,
                  },
                ]}
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                {...getOverrideProps(overrides, "ColumLine")}
              ></Icon>
            </Flex>
          </Flex>
          <Flex
            gap="4px"
            direction="row"
            width="211px"
            height="67px"
            justifyContent="center"
            alignItems="flex-end"
            shrink="0"
            position="relative"
            boxShadow="0px 0px 8px rgba(0, 0, 0, 0.36000001430511475)"
            borderRadius="22px 22px 0px 0px"
            padding="12px 16px 15px 16px"
            backgroundColor="var(--my-theme-header-footer-color)"
            {...getOverrideProps(overrides, "Note")}
          >
            <Flex
              gap="4px"
              direction="column"
              shrink="0"
              height="30px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NoteBody")}
            >
              <Flex
                gap="4px"
                direction="row"
                width="fit-content"
                justifyContent="center"
                alignItems="flex-end"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "NoteText")}
              >
                <Text
                  fontFamily="Shippori Mincho"
                  fontSize="26px"
                  fontWeight="600"
                  color="rgba(232,232,232,1)"
                  lineHeight="26px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-end"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="NOTE"
                  {...getOverrideProps(overrides, "NOTE")}
                ></Text>
                <Text
                  fontFamily="Shippori Mincho"
                  fontSize="18px"
                  fontWeight="600"
                  color="rgba(232,232,232,1)"
                  lineHeight="20px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="を使う"
                  {...getOverrideProps(overrides, "\u3092\u4F7F\u3046")}
                ></Text>
              </Flex>
              <Icon
                width="139px"
                height="0px"
                viewBox={{ minX: 0, minY: 0, width: 139, height: 1 }}
                paths={[
                  {
                    d: "M0 0L139 0L139 -1L0 -1L0 0Z",
                    stroke: "rgba(232,232,232,1)",
                    fillRule: "nonzero",
                    strokeWidth: 1,
                  },
                ]}
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                {...getOverrideProps(overrides, "NoteLine")}
              ></Icon>
            </Flex>
          </Flex>
        </Flex>
        <View
          width="423px"
          height="5px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="var(--my-theme-header-footer-color)"
          {...getOverrideProps(overrides, "Rectangle 1174")}
        ></View>
      </Flex>
    </View>
  );
}
